import React, {useState} from 'react';
import {Header} from "./components/layout/Header";
import {Map} from "./components/Map/Map";
import {SearchContext} from './contexts/search.context';
import {Route, Routes} from "react-router-dom";
import {AdForm} from "./components/AdForm/AdForm";

// 'Dodaj ogłoszenie'
export const App = () => {
    const [search, setSearch] = useState('');

    return (
        <SearchContext.Provider value={{search, setSearch}}>
            <Header/>
            {/*<Map/>*/}
            <Routes>
                <Route path="/" element={<Map/>}/>
                <Route path="/add" element={<AdForm/>}/>
            </Routes>
        </SearchContext.Provider>
    );

    // return (
    //     <>
    //         <Header/>
    //         <Map/>
    //     </>
    // );
}

